<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-15" style="margin-top: 0px;">
            <a-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb-24">
                <a-badge 
                    v-for="option in filterItems"
                    :key="option.uuid"
                    style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                    :count="`${option.name}    ${option.icon}`" 
                    @click="onSelectFilterItem(option.uuid)"
                    :number-style="pagination.filter == option.uuid ? 
                        {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'} : 
                        {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: 'transparent !important', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'}"/>
    
               
            </a-col>
		</a-row>

        <div class="mb-20 mt-20">

            <a-row :gutter="24" class="px-20">

                <a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10">
                    <a-card>
                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    class="ant-table-striped"
                                    :columns="columns" 
                                    :data-source="classes"
                                    :loading="loadingData" 
                                    :pagination="true" 
                                    :rowKey="record => record.uuid"
                                    width="100%" 
                                    style="width: 100%;">

                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
                                        </template>institutionType

                                        <template slot="name" slot-scope="row"> 
                                            <a-avatar :src="row.thumbnail != null ? row.thumbnail : 'https://d3oh23bkh3m9fb.cloudfront.net/defaults/placeholder-colored.png'" shape="square" class="mr-5"/>
                                            {{ formatName(row.name.length > 35 ? `${row.name.substring(0, 35)}...` : row.name ) }}
                                        </template>

                                        <template slot="type" slot-scope="row"> 
                                            {{ row.institutionType }}
                                        </template>

                                        <template slot="location" slot-scope="row"> 
                                            {{ row.location }}
                                        </template>

                                        <template slot="educationSystems" slot-scope="row"> 
                                            <a-badge  
                                                v-for="option in row.educationSystems"
                                                :key="option.uuid"
                                                style="font-size: 14px !important;" 
                                                :count="option.name" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> &nbsp;
                                        </template>

                                        <template slot="educationLevels" slot-scope="row"> 
                                            <a-badge  
                                                v-for="option in row.educationLevels"
                                                :key="option.uuid"
                                                style="font-size: 14px !important;" 
                                                :count="option.name" 
                                                :number-style="{backgroundColor: '#F1F1F1 !important', color: '#444444', paddingTop: '4px', height: '30px'}"/> &nbsp;
                                        </template>
                                        
                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="viewInstitution(row)"
                                                :loading="joinInstitutionLoading && row.uuid == joinInstitutionLoadingUuid"
                                                style="border-radius: 20px;">
                                                <!-- <a-icon type="eye" theme="outlined" />  -->
                                                 Join
                                            </a-button>
                                        </template>
                                        
                                </a-table>
                            </a-col>
                        </a-row>
                    </a-card>
                </a-col>
            </a-row>
        </div>

		<a-modal v-model="filter.showModal" :footer="null">
			
			<a-row :gutter="[24, 24]" class="mt-20 mb-0 pb-0">
				<a-col :span="24" class="my-0 py-0">
					<h6 style="font-weight: normal; font-size: 18px;" class="text-center">Select all options that apply to you</h6>
				</a-col>
			</a-row>

            <!-- <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
				<a-col :span="24" class="">
                    <label class=""><strong>Type</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.type">
                        <a-radio :value="'classes'">Classes</a-radio>
                        <a-radio :value="'notes'">Notes</a-radio>
                        <a-radio :value="'quizzes'">Quizzes</a-radio>
                    </a-radio-group>
				</a-col>
			</a-row> -->

            <a-row :gutter="[24, 24]" class="mt-10 mb-0 pb-0">
				<a-col :span="24" class="">
                    <label class="mb-0 pb-0"><strong>Price</strong></label>
                </a-col>
                <a-col :span="24" class="mt-5 pt-0">
                    <a-slider class="mt-0 pt-0 mb-0 pb-0" v-model="filter.price" :tooltip-open="true" :step="slider.step" :max="slider.max" :trackStyle="{background: '#734EBF', color: '#734EBF'}"/>
                    <p style="font-size: 12px; position: absolute; right: 20px; top: -24px;">Less than: TZS <strong>{{ filter.price.toLocaleString() }}</strong></p>
				</a-col>
			</a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
				<a-col :span="24" class="mb-5 pb-0">
                    <label class="mb-0 pb-0"><strong>Tutor’s Rating</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-rate class="mt-0 pt-0" v-model="filter.tutorRating" />
				</a-col>
			</a-row>


            <a-row :gutter="[24, 24]" class="mt-0 mb-0 pb-0">
				<a-col :span="24" class="">
                    <label class=""><strong>Start Time</strong></label>
                </a-col>
                <a-col :span="24" class="mt-0 pt-0">
                    <a-radio-group v-model="filter.startTime">
                        <a-radio :value="'today'">Starting Today</a-radio>
                        <a-radio :value="'week'">Starting Next week</a-radio>
                        <a-radio :value="'weeks'">Starting in 2 weeks</a-radio>
                    </a-radio-group>
				</a-col>
			</a-row>

			<a-row :gutters="24" class="mt-20 pt-20">
				<a-col :span="24" class="text-right">
					<a-button id="textBtn" type="text" class="text-primary txtBtn" @click="clearFilter">
						Clear all filters
					</a-button>
					&nbsp;&nbsp;
					<a-button type="primary" class="text-white txtBtn" style="" @click="submitFilter">
						Appy filters
					</a-button>
				</a-col>
			</a-row>
			
		</a-modal>

	</div>
</template>

<script>
    import { notification } from 'ant-design-vue';
	import WidgetCounter from '../../components/Widgets/WidgetCounter' ;


    const columns = [
        {
            title: 'S/N',
            scopedSlots: { customRender: 's_no' },
            width: 50
        },
        {
            title: 'Name',
            scopedSlots: { customRender: 'name' },
            width: 200
        },
        {
            title: 'Type',
            scopedSlots: { customRender: 'type' },
            width: 100
        },
        {
            title: 'Education System',
            scopedSlots: { customRender: 'educationSystems' },
            width: 100
        },
        {
            title: 'Education Levels',
            scopedSlots: { customRender: 'educationLevels' },
            width: 100
        },
        {
            title: 'Action',
            scopedSlots: { customRender: 'action' },
            width: 100
        },
    ];

	export default ({
		
		components: {
			WidgetCounter,
		},

		data() {
			return {

                columns,
				loadingData: false,
                joinInstitutionLoading: false,
                joinInstitutionLoadingUuid: null,

				classes: [],
				currentPage: 1,
                pagination: {
					search: '',
                    filter: 'all',
                    perPage: 10,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filterItems: [
                    { uuid: 'all', name: 'All Institutions ', icon: ' 🎓'},
                    { uuid: 'my-institutions', name: 'My Institutions ', icon: ' 👤'},
                ],

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },
      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getInstitutions();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getInstitutions();
                },
            },
            deep: true,
        },
		mounted () {
			let breadcrumbs = [
				{ title: 'Institutions', url: '/institutions-list', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.$root.$refs.Classes = this;
            this.initializeFilter();
			// this.getUserAcademicDetails()
            this.getInstitutions();
		},
		methods: {

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

			submitSearch(search) {
                this.pagination.search = search;
				this.pagination.currentPage = 1;
				this.getInstitutions();
			},

            viewInstitution(institution) {
                if(this.pagination.filter == 'my-institutions') {
                    this.$router.push(`/institutions-list/view/${institution.uuid}`);
                }else{
                    if(institution.verifyMembers == false) {

                        this.joinInstitution(institution);

                    }else{
                        this.$router.push(`/institutions-list/view/${institution.uuid}/join`);
                    }
                }
            },

            submitSearchFilter(filter) {
                this.filter.loading = false;
                this.pagination.search = filter.search;
                this.filter.price = filter.price;
                this.filter.isActive = filter.isActive;
                // this.filter.tutorRating = filter.tutorRating;
                // this.filter.startTime = filter.startTime,
				this.pagination.currentPage = 1;
				this.getInstitutions();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getInstitutions();
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getInstitutions();
            },

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getInstitutions();
            },

            async initializeFilter() {
                let filter = JSON.parse(await localStorage.getItem('filter'));
                
                console.log(filter)
                if(filter != null) {
                    if(filter.navigate == true) {
                        filter.navigate = false;
                        await localStorage.setItem('filter', null)
                        
                        this.pagination.search = filter.search;
                        this.filter.price = filter.price;
                        this.filter.tutorRating = filter.tutorRating;
                        this.filter.startTime = filter.startTime;
                    }
                }
            },

            onSelectFilterItem(item) {
                this.pagination.filter = item;

                this.getInstitutions();
            },

			async getInstitutions() {

				this.loadingData = true;

                let url = '';

                const userInfo = await localStorage.getItem('user_details')

                let userDetails = JSON.parse(userInfo);


                if(this.pagination.filter == 'all') {

                    const { currentPage, perPage, search } = this.pagination;
                    
                    if(search != null && search.length > 0) {
                        if(this.filter.isActive) {
                            url = `${this.$BACKEND_URL}/institutions/?search=${search}&filter=true&price=<${this.filter.price}&page=${currentPage}&size=${perPage}&populate=true`;
                        }else{
                            url = `${this.$BACKEND_URL}/institutions/?search=${search}&page=${currentPage}&size=${perPage}&populate=true`;
                        }
                    }else{
                        if(this.filter.isActive) {
                            url = `${this.$BACKEND_URL}/institutions/?filter=true&price=<${this.filter.price}&page=${currentPage}&size=${perPage}&populate=true`;
                        }else{
                            // if(educationLevel != null) {
                            //     url = `${this.$BACKEND_URL}/institutions/?educationLevels=${educationLevel}&page=${currentPage}&size=${perPage}&populate=true`;
                            // }else{
                                url = `${this.$BACKEND_URL}/institutions/?page=${currentPage}&size=${perPage}&populate=true`;
                            // }
                        }
                    }

                }

                if(this.pagination.filter == 'my-institutions') {
                    const currentPage = 1;
                    const perPage = 1000;
                    let { search } = this.pagination;

                    if(search != null && search.length > 0) {                       
                        url = `${this.$BACKEND_URL}/institutions/mine?search=${search}&page=${currentPage}&size=${perPage}&populate=true`;
                    }else{
                        url = `${this.$BACKEND_URL}/institutions/mine?page=${currentPage}&size=${perPage}&populate=true`;
                    }
                }

                // if(this.pagination.filter == 'favorites') {

                //     const currentPage = 1;
                //     const perPage = 1000;
                //     let { search } = this.pagination;

                //     if(search != null && search.length > 0) {                       
                //         url = `${this.$BACKEND_URL}/institutions/favorites?search=${search}&page=${currentPage}&size=${perPage}`;
                //     }else{
                //         url = `${this.$BACKEND_URL}/institutions/favorites?page=${currentPage}&size=${perPage}`;
                //     }
                // }
                

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classes = this.pagination.filter == 'my-institutions' ? response.data.data : response.data.data.filter(inst => !inst.mine );
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
						this.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },



            async joinInstitution(institution) {
				const userInfo = await localStorage.getItem('user_details')

				let userDetails = JSON.parse(userInfo);

				this.joinInstitutionLoading = true;
                this.joinInstitutionLoadingUuid = institution.uuid

				let url = `${this.$BACKEND_URL}/institutions/${institution.uuid}/member`;

				this.$AXIOS.post(url, {uuid: userDetails.uuid, isTutor: false, isStudent: true, isAdmin: false}).then(async(response) => {

                    this.notify('You have successfully joined the institution', 'success')
                    
                    this.getInstitutions();

					// if(!hasClass) {
						this.$router.push(`/institutions-list/view/${institution.uuid}`)
					// }

                    this.joinInstitutionLoading = false;

                }).catch(async(error) => {

                    console.log(error)
					
					this.joinInstitutionLoading = false;

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});
			},


            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	border-radius: 5px;
}

a:hover {
    color: inherit !important;
}
</style>